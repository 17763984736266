@import '../../../../styles/mixins.scss';
@import '../../../../styles/variables';

.wine-item-card {
  margin: 1em;
  @include for-tablet-portrait-up {
    width: $wine-item-card-width__tablet_up;
  }
  @include for-phone-only {
    width: $wine-item-card-width__phone;
  }

  &__card-header {
    display: flex;
    align-items: flex-end;
    text-shadow: 2px 2px black;
    font-size: 24px;
    margin-bottom: 8px;
    margin-left: 5px;
    margin-right: 5px;
    color: white;
    @include for-tablet-portrait-up {
      min-height: 120px;
    }
  }

  &__header-paragraph {
    margin-bottom: 0px;
  }

  &__rating-number {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    background-color: $form-input-color;
    box-shadow: 0px 0px 1px 1px darkslategray;
    border-radius: 24px;
    width: 100px;
    font-size: 20px;
  }

  &__rating-label {
    margin: 8px 0px 0px;
  }


  &__card-body {
    padding-top: 30px;
    border-radius: 14px;
    background-color: white;
    padding-bottom: 20px;
    width: inherit;
    min-height: 650px;

    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 5% 45% 45% 5%;

    &-col-1 {
      grid-column: 2;
      justify-self: center;
    }


    &-rating-col {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
    }

    &-col-2 {
      grid-column: 3;
    }

    &-row-1 {
      @include for-tablet-portrait-up {
        min-height: 400px;
      }
    }

    &-wine-row {
      grid-column-start: 2;
      grid-column-end: 4;
      justify-self: center;
    }

    &-line-row {
      grid-column-start: 2;
      grid-column-end: 4;
    }


  }
}

.wine-image {
  height: 24em;
  max-width: 124px;
}

.wine-item-card__label {
  color: black;
  margin: 0;
  @include for-phone-only {
    font-size: 18px;
  }
}

.card-header {
  background-color: $wine-item-card-header-background-color;
  color: $wine-item-card-header-text-color;
  display: flex;
  align-items: flex-end;
  font-size: 18px;
  min-height: 95px;
  @include for-phone-only {
    font-size: 22px;
  }
}


.info-text-item {
  margin-bottom: 16px;
}

.info-text-item--no-margin {
  margin: 0;
}

.wine-info-text {
  color: grey;
  font-size: 1em;
  margin: 6px 0;
  @include for-phone-only {
    font-size: 18px !important;
    margin-bottom: 10px;
    margin-top: 0;
  }

  &__rating_number {
    color: black;
    margin: 0;
  }

  &--closed {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}


.fits-to-image {
  height: 3em;
  margin: auto 5px;
  width: 3em;
}