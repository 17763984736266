@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";
.login {
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &-form-container {
    width: 400px;
    display: flex;
    flex-direction: column;
    @include for-phone-only {
      width: $form-width;
    }
  }
  &-button {
    margin-top: 10px;
  }

  &-input {
    background-color: $form-input-color;
    height: 40px;
    font-size: 1em;
    padding-left: $input_padding;
    border-radius: 24px;
    border: 1px solid grey;
    color: hsl(0,0%,20%)
  }

  &-title {
    margin: 20px 50px !important;
    color: $form-error-color !important;
  }

  &-hasError {
    margin: 5px auto;
    color: $form-error-color;
  }
}
.login-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}