@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.lookup {
  &-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &-details-container {
    margin: 12px;
    padding: 20px;
    box-shadow: 1px 0 2px black;
    background-color: $form-background-color;
    border-radius: 14px;
    min-width: 400px;
    @include for-phone-only {
      min-width: 300px;
    }
  }

  &__button {
    margin-top: 16px;
    width: 100%;
  }
}

.wine-details-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}