@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}

@mixin for-tablet {
    @media (min-width: 600px) and (max-width: 1200px) { @content; }
}

@mixin for-desktop {
    @media (min-width: 1200px) { @content; }
}
