@import "./styles/variables.scss";
@import './styles/mixins.scss';

.App {
  min-height: 100vh;
  margin-bottom:50px;
}

.App-logo {
  height: 130px;
  @include for-phone-only {
    height: 130px;
    width: 80vw;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-navbar {
  display: flex;
  justify-content: center;
  font-size: 24px;

  @include for-phone-only {
    font-size: 18px;
    flex-direction: column;
    align-items: center;
  }

  a {
    margin: 0 11px;
  }
}

.app-header-text {
  color: black;
  font-size: 60px;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}