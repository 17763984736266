.wine-details {
  &-container {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 5% 45% 45% 5%;
  }

  &-row-item {
    grid-column-start: 2;
    grid-column-end: 4;
    p {
      color: grey;
    }
  }

  &-item-col-1 {
    grid-column: 2;
    p {
      color: grey;
    }
  }

  &-item-col-2 {
    grid-column: 3;
    p {
      color: grey;
    }
  }

  &-title {
    grid-column-start: 2;
    grid-column-end: span 4;
    font-size: 20px;
    p {
      color: black;
    }
  }
}