@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.wine-item-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 85vw;
  margin: 0 auto;
}

.wine-list__hits-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wine-list__hits-paragraph {
  color: white;
  font-size: 22px;
  margin-bottom: 0;
}

.wine-list__filter-container {
  background-color: $form-background-color;
  border-radius: 24px;
  display: flex;
  margin: 10px auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60vw;
  min-width: 270px;
  max-width: 300px;
  @include for-phone-only {
    width: 70vw;
    max-width: 300px;
  }
}

.wine-list__radio-group {
  flex-wrap: nowrap !important;
  flex-direction: row !important;

  &-children {
    margin-top: 0 !important;
    display: inline-flex;
    > label {
      margin: 0 20px;
      }
    }
}