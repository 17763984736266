@import "../../../styles/variables";
@import "../../../styles/mixins.scss";
/* HIDE CHECKBOX */
[type=checkbox] { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}


/* IMAGE STYLES */
[type=checkbox] + img {
  cursor: pointer;
}

/* CHECKED STYLES */
[type=checkbox]:checked + img {
  background-color: $image-checkbox-checked-background-color;
  border-radius: 10%;
}

.image  {
  width: 50px;
  @include for-tablet-portrait-up {
    width: 60px;
  }
}

