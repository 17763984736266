@import "styles/variables.scss";
@import "styles/mixins.scss";

body, button {
  margin: 0;
  padding: 0;
  font-family: HelveticaNeue, serif;
  background-color: $base-background-color;
}

form {
    background-color: $form-background-color;
    color: $form-text-color;
    font-size: 1.2em;
    padding: 0.5em;
    margin: 1em 0;
    border-radius: 14px;
  @include for-phone-only {
    font-size: 1.1em;
  }
}

button {
  font-size: 20px;
  background: none;
  color: $button-text-color;
  padding: 4px 16px;
  border-radius: 14px;
  border-color: transparent;
}

.error{
  color: $form-error-color;
  font-size: 0.8em;
}

.wine-input-container {
  p {
    padding: 0px 0px 0px $input_padding;
  }
  input {
    font-size: 1em;
    padding: 0px 0px 0px $input_padding;
    border: none;
    background: none !important;
    width: 100%;
    height: 90%;
  }
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: $form-input-color;
  width: 100%;
  height: 35px;
  margin-top: 10px;
  font-size: 1em;
  border-radius: 24px;
  border: 1px solid grey;
  color: hsl(0,0%,20%)
}

.page-title {
  color: white;
  font-weight: normal;
}

.react-select {
  margin-top: 10px;
}

@font-face {
  font-family: HelveticaNeue;
  src: url("./fonts/HelveticaNeueMed.ttf");
}
  
  