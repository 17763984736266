@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
// TODO BETTER STRUCTURE OF SCSS.


.add-wine {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__wine-registered {
    display: flex;
    font-size: 30px;
    justify-content: center;
  }
}

.add-wine-form {
  margin: 12px;
  box-shadow: 1px 0 2px black;
  background-color: $form-background-color;
  border-radius: 14px;
  width: $form-width;
  max-width: 900px;

  @include for-phone-only {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 5% 90% 5%;
  }
  @include for-tablet-portrait-up {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 3% 44% 5% 44% 3%;
  }

  &__row {
    @include for-tablet-portrait-up {
      grid-column-start: 2;
      grid-column-end: 5;
    }

    @include for-phone-only {
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }

  &__col-1 {
    @include for-phone-only {
       grid-column-start: 2;
       grid-column-end: 3;
     }
    @include for-tablet-portrait-up {
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }


  &__col-2 {
    @include for-phone-only {
      grid-column-start: 2;
      grid-column-end: 3;
    }

    @include for-tablet-portrait-up {
      grid-column-start: 4;
      grid-column-end: 5;
    }
  }

  &__fits-to-grid {
    width: 100%;
    height: 100%;
    display: inline-grid;
    grid-template-columns: 33%33%30%;
    grid-template-rows: 33%33%30%;
    margin-top: 4px;
  }

  &__fits-to-cell {
    justify-self: center;
    padding: 4px;
  }

  &__label-button-container {
    display: flex;
    flex-direction: column;
  }


  &__buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  &__button-label {
    padding: 2px 4px 0;
  }

  &__button {
    background: none;
    display: flex;
    align-items: center;
    padding: 4px 0;
    &-add {
      grid-column-start: 1;
      font-size: 1rem;
    }

    &-reset {
      grid-column-start: 3;
      font-size: 1rem;
    }

    &--manual-reg {
      margin-top: 8px;
      font-size: 1rem;
    }
    &--upload {
      margin: 12px 0;
      font-size: 1rem;
    }
  }
}


.add-wine-error-validation {
  margin-left: 2px;
  text-decoration: underline;
  color: $form-error-color;
}

.form-control {
  background-color: #e8eeef!important;
}

.custom-select {
  background-color: #e8eeef!important;
}

.wine-picture {
  width: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.textfield-label {
  margin: 8px 4px;
}

