$form-text-color: black;
$base-background-color: #20292f;
$form-background-color: white;
$form-input-color: #f4f3f6;
$form-error-color: #E63D3D;
$header-background-color: #4B6566;
$wine-item-card-header-background-color: #4B6566;
$wine-item-card-header-text-color: #fff;
$image-checkbox-checked-background-color: #87d7f0;
$button-reset-color: #3281a8;
$button-confirm-color: #04d19a;
$button-text-color: #3f5354;

